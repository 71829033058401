/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import * as React from 'react';
import { navigate } from 'gatsby-link';
import {
  Card, CardContent, Container, Grid, Typography,
} from '@mui/material';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import { Link } from 'gatsby';
import Layout, { LayoutContext } from '../../components/Layout';
import background from '../../img/background.svg';
import facebook from '../../img/facebook.png';
import linkedin from '../../img/linkedin.png';
import twitter from '../../img/twitter.png';
import youtube from '../../img/youtube.png';
import tiktok from '../../img/tiktok.png';
import instagram from '../../img/instagram-logo-circle.png';
import phoneIcon from '../../img/phone-icon.svg';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function Contact() {
  const [state, setState] = React.useState({ isValidated: false });
  const { mobileMode } = React.useContext(LayoutContext);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <>
      <JsonLd
        url="https://www.squaddie.co/contact-us"
        datePublished="2023-03-10T08:00:00+08:00"
        // dateModified="2023-03-10T09:00:00+08:00"
        authorName="Squaddie"
        description="Have a question or comment about our golf foursome availability scheduler app? Get in touch with our team today! We're here to help you improve your tee time experience. Contact us now!"
        overrides={{
          '@type': 'SoftwareApplication',
          name: 'Squaddie',
          applicationCategory: 'UtilitiesApplication',
        }}
      />
      <GatsbySeo
        title="Contact Us | Squaddie - The Caddie for your Golf Squad"
        description="Have a question or comment about our golf foursome availability scheduler app? Get in touch with our team today! We're here to help you improve your tee time experience. Contact us now!"
        canonical="https://www.squaddie.co/contact-us"
      />
      <Container
        maxWidth="none"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: mobileMode ? 'center right' : 'top right',
          backgroundSize: mobileMode ? '100%' : '35%',
          backgroundRepeat: 'no-repeat',
        }}
        sx={mobileMode ? { py: 5 } : { pb: 20, pt: 10 }}
      >
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center" rowGap={mobileMode ? 4 : 8}>
            <Typography
              variant="h2"
              style={{
                fontFamily: 'Poppins',
                fontSize: '51px',
                fontWeight: '500',
                lineHeight: '56px',
                letterSpacing: '0em',
                textAlign: 'center',
              }}
            >
              Get in touch today!
            </Typography>
            <Card
              sx={
                mobileMode ? { borderRadius: '20px' } : { width: '75%', p: 3, borderRadius: '40px' }
              }
              elevation={4}
            >
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  direction={mobileMode ? 'column' : 'row'}
                  rowGap={2}
                >
                  <Grid item xs={12} md={7}>
                    <Grid container alignItems="center" columnGap={mobileMode ? 1 : 3}>
                      <img
                        src={phoneIcon}
                        alt="Phone Icon"
                        style={mobileMode ? { width: '14vw' } : {}}
                      />

                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: '22px',
                          fontWeight: '600',
                          lineHeight: '71px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                        }}
                      >
                        Contact Us Via Socials
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Grid container columnGap={mobileMode ? 2 : 1.5}>
                      {/* <img
                        src={facebook}
                        style={{ height: '46px', width: '46px' }}
                        alt="Facebook Icon"
                      />
                      <img
                        src={twitter}
                        style={{ height: '46px', width: '46px' }}
                        alt="Twitter Icon"
                      />
                      <img
                        src={youtube}
                        style={{ height: '46px', width: '46px' }}
                        alt="YouTube Icon"
                      />
                      <img
                        src={linkedin}
                        style={{ height: '46px', width: '46px' }}
                        alt="LinkedIn Icon"
                      /> */}
                      <Link to="https://www.instagram.com/squaddiegolf" target="_blank">
                        <img
                          src={instagram}
                          style={{ height: '46px', width: '46px' }}
                          alt="Instagram Icon"
                        />
                      </Link>
                      <Link to="https://www.tiktok.com/@squaddie_golf" target="_blank">
                        <img
                          src={tiktok}
                          style={{ height: '46px', width: '46px' }}
                          alt="TikTok Icon"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              sx={
                mobileMode
                  ? { width: '100%', p: 2, borderRadius: '14px' }
                  : { width: '100%', p: 5, borderRadius: '40px' }
              }
              elevation={4}
            >
              <CardContent>
                <form
                  name="contact"
                  method="post"
                  action="/contact-us/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:
                      <input name="bot-field" onChange={handleChange} />
                    </label>
                  </div>
                  <Grid container direction="column" rowGap={mobileMode ? 2 : 6}>
                    <Grid item>
                      <Grid container rowGap={2}>
                        <Grid item xs="12" md="6" sx={!mobileMode && { pr: 6 }}>
                          <div className="field">
                            <label
                              className="label"
                              htmlFor="first_name"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '30px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              First Name
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                name="first_name"
                                onChange={handleChange}
                                id="first_name"
                                required
                                style={{
                                  borderColor: '#D3D3D3',
                                  borderRadius: '20px',
                                  fontFamily: 'Poppins',
                                  fontSize: '19px',
                                  fontWeight: '400',
                                  lineHeight: '29px',
                                  letterSpacing: '0em',
                                  textAlign: 'left',
                                  height: '80px',
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs="12" md="6">
                          <div className="field">
                            <label
                              className="label"
                              htmlFor="last_name"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '30px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              Last Name
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                name="last_name"
                                onChange={handleChange}
                                id="last_name"
                                required
                                style={{
                                  borderColor: '#D3D3D3',
                                  borderRadius: '20px',
                                  fontFamily: 'Poppins',
                                  fontSize: '19px',
                                  fontWeight: '400',
                                  lineHeight: '29px',
                                  letterSpacing: '0em',
                                  textAlign: 'left',
                                  height: '80px',
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container rowGap={2}>
                        <Grid item xs="12" md="6" sx={!mobileMode && { pr: 6 }}>
                          <div className="field">
                            <label
                              className="label"
                              htmlFor="email"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '30px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              Email
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                id="email"
                                required
                                style={{
                                  borderColor: '#D3D3D3',
                                  borderRadius: '20px',
                                  fontFamily: 'Poppins',
                                  fontSize: '19px',
                                  fontWeight: '400',
                                  lineHeight: '29px',
                                  letterSpacing: '0em',
                                  textAlign: 'left',
                                  height: '80px',
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs="12" md="6">
                          <div className="field">
                            <label
                              className="label"
                              htmlFor="Subject"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '30px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              Subject
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                name="Subject"
                                onChange={handleChange}
                                id="Subject"
                                required
                                style={{
                                  borderColor: '#D3D3D3',
                                  borderRadius: '20px',
                                  fontFamily: 'Poppins',
                                  fontSize: '19px',
                                  fontWeight: '400',
                                  lineHeight: '29px',
                                  letterSpacing: '0em',
                                  textAlign: 'left',
                                  height: '80px',
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="field">
                      <label
                        className="label"
                        htmlFor="message"
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: '20px',
                          fontWeight: '500',
                          lineHeight: '30px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                        }}
                      >
                        Message
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name="message"
                          onChange={handleChange}
                          id="message"
                          required
                          style={{
                            borderColor: '#D3D3D3',
                            borderRadius: '20px',
                            fontFamily: 'Poppins',
                            fontSize: '19px',
                            fontWeight: '400',
                            lineHeight: '29px',
                            minHeight: '50vh',
                            letterSpacing: '0em',
                            textAlign: 'left',
                          }}
                        />
                      </div>
                    </div>
                    <Grid item alignSelf="center">
                      <div className="field">
                        <button
                          className="button is-link"
                          type="submit"
                          style={{
                            padding: '2rem',
                            backgroundColor: '#5EBC67',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '25px',
                            letterSpacing: '0em',
                            borderRadius: '41px',
                          }}
                        >
                          Send Message
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

function Index() {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
}

export default Index;
